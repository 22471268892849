@use "@carbon/react";

@import url("https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
.bx--btn,
.bx--modal,
.bx--data-table {
  font-family: "Hanken Grotesk", sans-serif;
}

// remove blue border/outline on focus
a.cds--header__menu-item:focus,
a.cds--side-nav__link:focus {
  border-color: transparent !important;
  outline-color: transparent !important;
}

.cds--data-table-content {
  overflow-x: visible; // to display tooltips in Accounts
}
.cds--data-table--md tbody tr {
  height: 3.25rem;
}
.cds--data-table--md tbody td {
  max-width: 320px; // to avoid descriptions to take too much space
}
.cds--data-table--md tbody a,
.cds--structured-list-td a {
  color: var(--cds-text-primary);

  svg {
    vertical-align: middle;
  }

  &.filter {
    text-decoration-style: dashed;
  }
}
.cds--data-table--md tbody td .desc {
  // add ... to <div class="desc"> used for descriptions
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.circular-image-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
}

.circular-image {
  width: 30px; /* Adjust size as needed */
  height: 30px; /* Adjust size as needed */
  border-radius: 50%;
  object-fit: cover;
}

.cds--data-table--md tbody tr.selected {
  font-weight: bold;
}

.cds--data-table--md tbody tr[disabled] td {
  background: var(--cds-background);
}
.cds--data-table--md tbody tr[disabled]:hover td {
  background: var(--cds-layer-hover);
}

.cds--structured-list-td a + a {
  margin-left: 8px;
}
.cds--structured-list-td.strong {
  font-weight: bold;
}

.cds--structured-list .cds--structured-list .cds--structured-list-row {
  border-top: 0;

  &:last-child {
    border-bottom: 0;
  }
}

/*
  display addresses 

  usage:
  <p>0x0ECE90<span class="addr-ellipsis"></span><span class="addr-hidden">EF4a12273E9c2C06E7C86075d021</span>DB5A6A</p>

  it renders:
  0x0ECE90...DB5A6A

  it copies & pastes:
  0x0ECE90EF4a12273E9c2C06E7C86075d021DB5A6A
*/
.addr-ellipsis::before {
  content: "…";
  // content: '...';
}

.addr-hidden {
  font-size: 0;
  width: 0;
  height: 0;
  display: inline-block;
}

.hidden-comma::before {
  content: ",";
}

.strikeout {
  text-decoration: line-through;
}

/*
 modal views
*/
.cds--structured-list.hover .cds--structured-list-row:hover {
  border-color: var(--cds-layer-hover);
  background-color: var(--cds-layer-hover);
}

/*
 toolbar for batch actions
*/
.cds--batch-actions,
.cds--batch-summary {
  background-color: transparent;
  color: var(--cds-text-primary);
}
.cds--batch-summary__cancel {
  display: none;
}
.cds--table-toolbar {
  .cds--btn--primary {
    @extend .cds--btn--tertiary;
    color: var(--cds-button-tertiary);
  }

  .cds--btn--danger {
    @extend .cds--btn--tertiary;
    color: var(--cds-button-danger-primary);
    border-color: var(--cds-button-danger-primary);

    &:hover {
      background-color: var(--cds-button-danger-primary);
      color: var(--cds-text-on-color);
    }
  }
}

/* forms */

.form-2cols {
  display: flex;

  .cds--label {
    height: 16px;
  }

  .cds--form-item,
  .form-2cols {
    width: 50%;
  }

  .cds--form-item + .cds--form-item {
    margin-left: 16px;
  }

  .form-2cols + .cds--form-item,
  .cds--form-item + .form-2cols {
    margin-left: 16px;
  }
}
.cds--text-input__field-outer-wrapper {
  height: 60px;
}
.cds--list-box__wrapper,
.cds--number--helpertext {
  height: 84px;
}

.min-width-button {
  min-width: 140px; // Adjust this value as needed
}
.cds--front-line {
  width: 100%;
  display: inline-block;
  direction: rtl;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@keyframes slideInRightToLeft {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.notifications-container {
  position: fixed;
  top: 2.5rem;
  right: 0.5rem;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.notification-slide-in {
  animation: slideInRightToLeft 0.5s ease-out forwards;
}

.connection-pill {
  position: absolute;
  top: -2px;
  right: -2px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: gray; /* Default color */
}

.connection-pill.connected {
  background-color: dodgerblue; /* Color when connected */
}

.connection-pill.disconnected {
  background-color: grey; /* Color when disconnected */
}

.breadcrumb {
  display: flex;
  margin-bottom: 1rem;
  gap: 1rem;
}

.breadcrumb-item {
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.breadcrumb-item.active {
  background-color: #0f62fe;
  color: white;
}

.breadcrumb-item:hover {
  background-color: #e0e0e0;
}
