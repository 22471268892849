.tree-node {
  display: flex;
  flex-direction: column;
}

.node-label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.toggle-button {
  margin-right: 8px;
}
